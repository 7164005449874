<template>
  <div class="message-box">
    <div class="btn-send" @click="newMessage">
      <div class="icon-box">
        <i class="bi bi-plus-lg"></i>
      </div>
      Send new message
    </div>
    <contact-list :contacts="combineContacts" />
  </div>
</template>

<script>
import ContactList from '@/components/ContactList/index'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'message',
  components: {
    ContactList
  },
  computed: {
    ...mapGetters([
      'contacts',
      'lastMessages',
      'fromPhone',
      'unreadMessage'
    ]),
    combineContacts: function() {
      const contacts = this.contacts
      const unreadMessage = this.unreadMessage
      const lastMessages = this.lastMessages
      const contactMap = {}
      // 读取最新消息
      for (let i = 0; i < contacts.length; i++) {
        const element = contacts[i];
        contactMap[element.phone] = element
      }
      // 读取未读消息
      const unreadStat = {}
      for (let i = 0; i < unreadMessage.length; i++) {
        const element = unreadMessage[i];
        if (this.fromPhone && this.fromPhone.fullNumber === element.from) {
          if (unreadStat[element.to]) {
            unreadStat[element.to]++
          } else {
            unreadStat[element.to] = 1
          }
        }
      }
      // 将未读消息和最新消息组装一起
      const topContacts = []
      for (let i = 0; i < lastMessages.length; i++) {
        const lastMessage = lastMessages[i]
        const item = {}
        item.lastMessage = lastMessage.content
        item.time = lastMessage.time

        const contact = contactMap[lastMessage.phone]
        // 最后一条消息赋值
        if (contact) {
          item.color = contact.color
          item.phone = contact.phone
          item.receive = contact.receive
        }
        // 未读消息统计
        if (unreadStat[item.phone]) {
          item.unread = unreadStat[item.phone]
        } else {
          item.unread = 0
        }
        topContacts.push(item)
      }
      return topContacts
    }
  },
  data() {
    return {
      selectCount: 0
    }
  },
  created() {
  },
  methods: {
    ...mapActions([
      'SetContact'
    ]),
    newMessage() {
      this.$store.dispatch('sms/UpdateSelectContact', null)
    }
  }
}
</script>
<style rel="stylesheet/scss" lang="scss">
.btn-send:active {
  background-color: #dadce0;
}
.btn-send {
  user-select: none;
  height: 64px;
  box-sizing: border-box;
  display: flex;
  font: 500 14px/20px "Google Sans","Helvetica Neue",sans-serif;
  letter-spacing: .25px;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #dadce0;
  .icon-box {
    font-size: 20px;
    font-weight: bold;
    margin: 0 22px 0 22px;
    color: #00796b;
  }
}
.message-box {
  height: 100%;
  display: flex;
  flex-direction: column;
  .select-box {
    display: flex;
    align-items: center;
    height: 64px;
    position: relative;
    padding: 0 10px 0 15px;
    box-sizing: border-box;
    font: 400 16px/24px Roboto,"Helvetica Neue",sans-serif;
    letter-spacing: .1px;
    span {
      color: #00695c;
      font: 500 16px/24px "Google Sans","Helvetica Neue",sans-serif;
      letter-spacing: .1px;
      flex: 1;
      text-align: left;
    }
    .icon-box {
      color: #00695c;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .select-box::after {
    border-bottom: none;
    border-top: 3px solid #006972;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    bottom: 0;
    content: " ";
    height: 0;
    left: 0;
    position: absolute;
    width: 100%;
  }
}
</style>
  